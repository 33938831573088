import { useEffect, useState } from 'react';

export interface AnalyticsEvent {
  name?: EventName;
  event_action?: string;
  event_category?: EventCategory | number;
  event_label: EventLabel | string;
  params?: {
    currency?: string;
    coupon?: string;
    items?: AnalyticsItem[];
  };
  custom?: {
    [Key: string]: unknown;
  };
  non_interaction?: boolean;
}

export interface AnalyticsItem {
  id: string;
  quantity: number | string;
  price: string;
}

export enum FavouritesEventSource {
  FAVOURITES = 'favourites',
  REC_FAVOURITES = 'rec-favourites',
  FAVOURITES_ADD_ALL = 'favourites_add_all',
}

export enum EventCategory {
  CART = 'cart',
  ECOMMERCE = 'ecommerce',
  ERROR = 'error',
}

export enum EventName {
  ADD_TO_CART = 'add_to_cart',
  CART_MERGE_CONFLICT = 'cart_merge_conflict',
  CREATE_LIST = 'create_list',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  REMOVE_FROM_WISHLIST = 'remove_from_wishlist',
}

export enum EventLabel {
  CART_VIEW = 'cart_view',
  CART = 'cart',
}

export interface ISendErrorEvent {
  eventName: EventName;
  error: any;
}

export const useAnalytics = (): {
  sendEvent: (event: AnalyticsEvent) => void;
  sendErrorEvent: (data: ISendErrorEvent) => void;
} => {
  const [events, setEvents] = useState<AnalyticsEvent[]>([]);

  useEffect(() => {
    if (window?.sendEvent && events.length > 0) {
      events.forEach((event) => {
        try {
          window.sendEvent(event);
        } catch {
          console.log('Failed to send event');
        }
      });

      setEvents([]);
    }
  }, [events]);

  const sendEvent = (event: AnalyticsEvent) => {
    setEvents([...events, event]);
  };

  const sendErrorEvent = (data: ISendErrorEvent) => {
    sendEvent({
      event_category: EventCategory.ERROR,
      event_action: data.eventName,
      event_label: data.error?.message ?? 'Unknown error',
      custom: {
        error_id: data.error?.type ?? 'Unknown type',
        error_message: data.error?.message ?? 'Unknown error',
        error_code: data.error?.status?.toString() ?? '500',
      },
      non_interaction: true,
    });
  };

  return { sendEvent, sendErrorEvent };
};
