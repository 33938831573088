export enum ProviderError {
  NO_IDENTITY_PROVIDER = 'no_identity_provider',
  NO_AUTH_CREDENTIALS = 'no_auth_credentials',
}
export enum NetworkError {
  IRW_COOKIE_MISSING = 'irw_cookie_missing',
  CART_NOT_FOUND = 'cart_not_found',
  LINE_NOT_FOUND = 'line_not_found',
  NO_ACCESS = 'no_access',
}
export enum Provider {
  IRW = 'irw',
}
