import { useState, useEffect } from 'react';
import { getAuth0Session } from '../network/endpoints/profile-pages/getAuth0Session';

export default function useAuth0() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getSessionStatus = async () => {
    setIsLoading(true);
    const sessionStatus = await getAuth0Session();
    setIsAuthenticated(sessionStatus.isAuthenticated);
    setIsLoading(false);
  };

  useEffect(() => {
    getSessionStatus();
  }, [isAuthenticated]);

  return {
    isAuthenticated,
    isLoading,
  };
}
