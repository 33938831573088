import { useTranslation } from 'react-i18next';

import { useCart } from '../../hooks/useCart';
import { ConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal';

export const MvAgentCart = () => {
  const { showItemDoesNotExistModal, setShowItemDoesNotExistModal } = useCart();
  const { t } = useTranslation('translations');

  return (
    <ConfirmationModal
      isOpen={showItemDoesNotExistModal}
      labels={{
        modal: {
          title: t('fa.mvAgent.itemDoesNotExistModal.title'),
          body: t('fa.mvAgent.itemDoesNotExistModal.body.text'),
        },
        buttons: {
          primary: {
            label: t('fa.mvAgent.itemDoesNotExistModal.closeButton.label'),
          },
        },
      }}
      onClose={() => setShowItemDoesNotExistModal(false)}
      onConfirm={async () => setShowItemDoesNotExistModal(false)}
    />
  );
};
