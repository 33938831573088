import './Text.scss';

import SkapaText, { TextProps as SkapaTextProps } from '@ingka/text';

import { PREFIX } from '../constants/constants';

export enum TextTagName {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  SPAN = 'span',
  P = 'p',
}

export enum TextHeadingSize {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
}

export enum TextBodySize {
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
}

const Text = (props: SkapaTextProps) => <SkapaText prefix={PREFIX} {...props} />;

export default Text;
