export const retailUnitsWithoutCookieConsent = [
  'mx',
  'th',
  'sg',
  'il',
  'ae',
  'jo',
  'ma',
  'ph',
  'kw',
  'om',
  'qa',
  'eg',
  'my',
];
