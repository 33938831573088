import './AddToFavouritesModal.scss';

import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import documentAddIcon from '@ingka/ssr-icon/paths/document-add';

import { useLoading } from '../../../hooks/useLoading';
import Button from '../../Skapa/Button/Button';
import Choice, { ChoiceItem, ChoiceItemProps as SkapaChoiceItemProps } from '../../Skapa/Choice/Choice';
import { ImageProps as SkapaImageProps } from '../../Skapa/Image/Image';
import Modal, { ModalBody, ModalFooter, ModalHeader, SheetSize, Sheets } from '../../Skapa/Modal/Modal';
import Text, { TextHeadingSize, TextTagName } from '../../Skapa/Text/Text';
import InlineMessage, { InlineMessageVariant } from '../../Skapa/InlineMessage/InlineMessage';

type Labels = {
  modal: {
    title: string;
    body: string;
  };
  footer: {
    createNewList: {
      label: string;
    };
  };
  warning: {
    maxListsReached: {
      title: string;
      body: string;
      action: string;
    };
  };
};
export enum MoveItemsModalStatus {
  MAX_LISTS_REACHED = 'MAX_LISTS_REACHED',
}
export enum Error {
  UNKNOWN = 'unknown',
}
export type ChoiceProps = Pick<SkapaChoiceItemProps, 'id' | 'title'> & {
  image: SkapaImageProps;
};
enum LocalLoading {
  SELECT = 'select',
}
export interface AddToFavouritesModalProps {
  isOpen: boolean;
  status?: MoveItemsModalStatus;
  labels: Labels;
  choices: ChoiceProps[];
  onClose: () => void;
  onBack?: () => void;
  onSelect: (listId: string) => Promise<string>;
  onSelectCallback: (listId: string, listName: string, itemId: string) => void;
  onSelectFallback: (error: any) => void;
  onCreateNewList: () => void;
  onGoToFavourites: () => void;
}
export const AddToFavouritesModal = (props: AddToFavouritesModalProps) => {
  const {
    isOpen,
    status,
    labels,
    choices,
    onClose,
    onBack,
    onSelect,
    onCreateNewList,
    onSelectCallback,
    onSelectFallback,
    onGoToFavourites,
  } = props;
  const localLoading = useLoading<LocalLoading>([]);

  return (
    <Modal id={'add-to-favourites-modal'} visible={isOpen} handleCloseBtn={() => onClose()}>
      <Sheets
        id={'add-to-favourites-modal'}
        data-testid={'add-to-favourites-modal'}
        header={<ModalHeader title={labels.modal.title} backBtnClick={onBack && (() => onBack())} />}
        size={SheetSize.SMALL}
        footer={
          status !== MoveItemsModalStatus.MAX_LISTS_REACHED ? (
            <ModalFooter>
              <Button
                ssrIcon={documentAddIcon}
                fluid={true}
                onClick={() => {
                  onCreateNewList();
                }}
                text={labels.footer.createNewList.label}
                disabled={localLoading.check(LocalLoading.SELECT)}
              />
            </ModalFooter>
          ) : (
            <InlineMessage
              ssrIcon={warningTriangleIcon}
              variant={InlineMessageVariant.CAUTIONARY}
              title={labels.warning.maxListsReached.title}
              body={labels.warning.maxListsReached.body}
              actions={[
                {
                  text: labels.warning.maxListsReached.action,
                  onClick: () => onGoToFavourites(),
                },
              ]}
            />
          )
        }
      >
        <ModalBody>
          <Text tagName={TextTagName.H1} headingSize={TextHeadingSize.M} className={'add-to-fav-body'}>
            {labels.modal.body}
          </Text>
          <Choice
            data-testid={'add-to-favourites-modal-choice'}
            subtle={true}
            onSelect={async (choice) => {
              try {
                localLoading.set(LocalLoading.SELECT);
                const itemId = await onSelect(choice);
                onSelectCallback(choice, choice.title, itemId);
              } catch (error: any) {
                onSelectFallback(error);
              } finally {
                localLoading.unset(LocalLoading.SELECT);
              }
            }}
          >
            {choices.map((choice) => (
              <ChoiceItem
                key={choice.id}
                id={choice.id}
                data-testid={'add-to-favourites-modal-choice-item'}
                title={choice.title}
                image={{
                  alt: choice.image?.alt,
                  src: choice.image?.src,
                  srcSet: choice.image?.srcSet,
                  fallback: choice.image?.fallback,
                }}
                disabled={localLoading.check(LocalLoading.SELECT)}
              />
            ))}
          </Choice>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
