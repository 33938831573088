import { cartAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { TCartItem } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export interface GetCartOptions {
  queryParams: {
    skipSync: boolean;
    includePrices: boolean;
  };
}

export const getCart = async (options: GetCartOptions) => {
  const path = endpointReplace({ ':siteType': SiteTypes.CART }, Endpoints.GetCart);

  const response = await network.get<TCartItem[]>(`${path}`, { params: options.queryParams });

  return response.data;
};
