import './LoginModal.scss';

import Cookies from 'js-cookie';
import Loading, { LoadingBall } from '@ingka/loading';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Actions } from '../../../actions/enums';
import { PREFIX } from '../../Skapa/constants/constants';
import Modal, { ModalBody, ModalHeader, SheetSize, Sheets } from '../../Skapa/Modal/Modal';
import { splitPath } from '@mvecom/common/utils/splitPath';

import { LoginEvent, useLogin } from '../../../hooks/useLogin';
import { useBreakpoint } from '@mvecom/common/hooks/useBreakpoint';

import { LoginPage } from '../../Login/LoginPage';
import { ProfilePagesUrls } from '../../../utils/Urls';
import { useCartMergeListener } from '../../../hooks/useCart';

export const LoginModal = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [state, setState] = useState<
    'closed' | 'reminder' | 'redirect' | 'reminder-merge-check' | 'menu' | 'menu-iframe-loading'
  >('closed');

  const { isMobile } = useBreakpoint();

  const iframeUrl = useMemo(() => ProfilePagesUrls.menu(), []);

  useEffect(() => {
    if (state !== 'menu') {
      return;
    }

    iframeRef.current?.contentWindow?.postMessage({ mobile: isMobile }, iframeUrl);
  }, [iframeUrl, isMobile, state]);

  useEffect(() => {
    if (state === 'redirect') {
      window.ikea.pubsub.publish(Actions.GO_TO_CHECKOUT);
    }
  }, [state]);

  const close = useCallback(() => setState('closed'), [setState]);

  useCartMergeListener(
    useCallback(
      (mergeStatus) => {
        if (state !== 'reminder' && state !== 'reminder-merge-check') {
          return;
        }

        switch (mergeStatus) {
          case 'merged':
          case 'failed':
            close();
            return;
          case 'did-nothing':
            window.ikea.pubsub.publish(Actions.GO_TO_CHECKOUT);
            return;
          default:
            mergeStatus satisfies never;
        }
      },
      [close, state],
    ),
  );

  const onLoginEvent = useCallback(
    (ev: LoginEvent) => {
      if (ev === 'initial-auth-check-false') {
        if (state === 'menu-iframe-loading') {
          setState('menu');
        }
      } else if (ev === 'logged-out') {
        setState('closed');
      } else if (ev === 'login-success') {
        if (state === 'menu' || state === 'menu-iframe-loading') {
          setState('closed');
        } else if (state === 'reminder') {
          setState('reminder-merge-check');
        }
      } else if (ev === 'validation-failure') {
        window.location.href = ProfilePagesUrls.missingInfo();
      }
    },
    [state],
  );

  const { loggedIn, login, loginError, loadingLoggedIn, loginLoading } = useLogin(state !== 'closed', onLoginEvent);

  // listen to pubsub messages for opening the menu
  useEffect(() => {
    const open = () => {
      if (!onProfilePages()) {
        /*
          We can handle menu better on profile pages
          faster loading, avoid whole navigation when clicking on links
        */
        setState('menu-iframe-loading');
      }
    };

    const openLoginReminder = () => {
      if (Cookies.get('_session_id')) {
        setState('redirect');
      } else {
        setState('reminder');
      }
    };

    window?.ikea?.pubsub?.subscribe(Actions.PROFILE_OPEN_MENU, open);
    window?.ikea?.pubsub?.subscribe(Actions.PROFILE_OPEN_LOGIN_REMINDER, openLoginReminder);

    return () => {
      window?.ikea?.pubsub?.unsubscribe(Actions.PROFILE_OPEN_MENU, open);
      window?.ikea?.pubsub?.unsubscribe(Actions.PROFILE_OPEN_LOGIN_REMINDER, openLoginReminder);
    };
  }, [loggedIn, setState]);

  // listen to post messages
  useEffect(() => {
    const ev = (event: MessageEvent) => {
      const pubsubStart = 'pubsub::';

      if (event.data === 'menu-loaded' && !onProfilePages()) {
        setState('menu');
      } else if (event.data === 'menu-close') {
        close();
      } else if (typeof event.data === 'string' && event.data.startsWith(pubsubStart)) {
        window?.ikea?.pubsub?.publish?.(event.data.slice(pubsubStart.length));
      }
    };
    window.addEventListener('message', ev);
    return () => window.removeEventListener('message', ev);
  }, [close]);

  if (state === 'closed') {
    return <Wrapper visible={false} close={close} />;
  }

  if (state === 'menu' || state === 'menu-iframe-loading') {
    if (loadingLoggedIn) {
      return <Wrapper loading visible close={close} />;
    }

    return (
      <Wrapper visible loading={state === 'menu-iframe-loading' && (loadingLoggedIn || loggedIn)} close={close}>
        {loggedIn ? (
          <iframe
            title="profile pages"
            src={iframeUrl}
            className="profilePagesIframe"
            ref={iframeRef}
            sandbox="allow-popups allow-top-navigation allow-same-origin allow-scripts allow-forms"
          />
        ) : (
          <LoginPage
            closeMenu={close}
            isReminder={false}
            skapaPrefix={PREFIX}
            login={login}
            loading={loginLoading}
            loginError={loginError}
          />
        )}
      </Wrapper>
    );
  }

  if (state === 'reminder' || state === 'reminder-merge-check') {
    return (
      <Wrapper visible close={close}>
        <LoginPage
          closeMenu={close}
          isReminder
          skapaPrefix={PREFIX}
          login={login}
          loading={loginLoading || state === 'reminder-merge-check'}
          loginError={loginError}
        />
      </Wrapper>
    );
  }
};

const Wrapper = ({
  children,
  visible,
  loading,
  title,
  close,
}: {
  children?: ReactNode;
  loading?: boolean;
  visible: boolean;
  title?: string;
  close: () => void;
}) => (
  <Modal prefix={PREFIX} id="menuModal" visible={visible} handleCloseBtn={close}>
    <Sheets
      prefix={PREFIX}
      id="menuSheetsModal"
      header={<ModalHeader title={title} />}
      size={SheetSize.SMALL}
      footer={null}
      preserveAlignment
    >
      <ModalBody prefix={PREFIX} className="skapasModalBody">
        <div className="menuModalBodyContainer">
          {!!loading && (
            <Loading prefix={PREFIX} text="">
              <LoadingBall prefix={PREFIX} />
            </Loading>
          )}
          {children}
        </div>
      </ModalBody>
    </Sheets>
  </Modal>
);

function onProfilePages() {
  const [, , appName] = splitPath(window.location.pathname);
  return appName === 'profile';
}
