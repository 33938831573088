import { SiteTypes } from '../enums';

export const getRetailUnitAndLanguage = () => {
  const { pathname } = window.location;
  const pathParams = pathname.split('/');
  pathParams.shift();
  const [retailUnit, language] = pathParams;
  return { retailUnit, language };
};

export const getOrigin = window.location.origin;

export const getEndpointURL = (siteType: SiteTypes, route?: string) => {
  const { retailUnit, language } = getRetailUnitAndLanguage();

  const origin = getOrigin;
  const formattedRoute = route ? `${route}/` : '';

  return `${origin}/${retailUnit}/${language}/${siteType}/${formattedRoute}`;
};
