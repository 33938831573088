// Endpoint must end with trailing slash (do not remove)
export enum Endpoints {
  AddItemsToCart = '/:siteType/cart/items/',
  GetCart = '/:siteType/cart/contents/',
  Lists = '/:siteType/api/lists/',
  AddItemToFavourites = '/:siteType/api/lists/:listId/items/',
  GetItemMetadataCompact = '/:siteType/api/item-meta-data/items/:itemNos/compact/',
  RemoveItemFromFavourites = '/:siteType/api/lists/:listId/items/:itemId/',
  PostUserReplacement = '/:siteType/api/user/replace/',
  PostCartUserReplacement = '/:siteType/cart/user/replace/',
  GetSession = '/:siteType/api/user/session/',
  GetCartSession = '/:siteType/cart/user/session/',
  Auth = '/:siteType/api/authentication/',
  GetAuth0Session = '/auth/status/',
}

export enum SiteTypes {
  CART = 'cart',
  FAVOURITES = 'favourites',
  PROFILE = 'profile',
}

export enum Cookie {
  CART_GUEST_ID = 'MV_CART_GUEST_ID',
  CART_GUEST_PROVIDER = 'MV_CART_GUEST_PROVIDER',
  CART_COUNT = 'IRMW_CART_COUNT_',
  FAVOURITES_GUEST_ID = 'MV_FAVOURITES_GUEST_ID',
  FAVOURITES_GUEST_PROVIDER = 'MV_FAVOURITES_GUEST_PROVIDER',
  USER_LOGGEDIN = 'MV_USER_LOGGEDIN',
  IKEA_SESSION = 'ikea_session',
  MV_CART_ID = 'mvCartId',
  IRW_SESSION = 'WC_SESSION_ESTABLISHED',
}

export enum Storage {
  FAVOURITES_ITEM_STATE = 'MV_FAVOURITES_ITEM_STATE',
  CART_SHOW_MERGE_TOAST = 'MV_CART_SHOW_MERGE_TOAST',
}

export enum SyntheticEvent {
  ADD_TO_CART = 'addtocart',
}
