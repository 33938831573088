import { FormEvent, ReactNode, RefObject } from 'react';

import { formValidation } from '../../utils/formValidation';

export enum EventName {
  SUBMIT = 'submit',
}
interface FormProps {
  children: ReactNode;
  className?: string;
  onSubmit: () => void;
  reference: RefObject<HTMLFormElement>;
}
export const Form = (viewModel: FormProps) => {
  const { children, className, onSubmit, reference } = viewModel;

  return (
    <form
      className={className}
      data-testid="input-form"
      noValidate
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const valid = formValidation(reference.current as HTMLFormElement);
        if (!valid) return;
        onSubmit();
      }}
      ref={reference}
    >
      {children}
    </form>
  );
};
