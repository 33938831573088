import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { GenericResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const deleteItemFromList = async (listId: string, itemId: string) => {
  const path = endpointReplace(
    { ':siteType': SiteTypes.FAVOURITES, ':listId': listId, ':itemId': itemId },
    Endpoints.RemoveItemFromFavourites,
  );

  const response = await network.delete<GenericResponse>(path);

  return response.data;
};
