import { splitPath } from '@mvecom/common/utils/splitPath';

function getApiGwOrigin() {
  if (window.location.host === 'www.ikea.com') {
    return 'https://api.commerce.ikea.com';
  } else if (window.location.host === 'www.cte.ikeadt.com') {
    return 'https://api.commerce.ikeadt.com';
  }
  return '';
}

export const backendUrl = (port?: number) => {
  const portRegex = /:\d{4}/;

  const [retailUnit, language] = splitPath(window.location.pathname);
  let origin = getApiGwOrigin();

  if (port && portRegex.exec(window.location.origin)) {
    origin = window.location.origin.replace(portRegex, ':' + port);
  }
  return `${origin}/${retailUnit}/${language}`;
};
