import './FormField.scss';

import SkapaFormField, { FormFieldProps as SkapaFormFieldProps } from '@ingka/form-field';

import { PREFIX } from '../constants/constants';

export type { SkapaFormFieldProps as FormFieldProps };

const FormField = (props: SkapaFormFieldProps) => <SkapaFormField prefix={PREFIX} {...props} />;

export default FormField;
