import { JSX, createContext, useCallback, useMemo, useState } from 'react';

import { initLanguage } from '../i18n/initLanguage';

export const TranslationsContext = createContext(
  {} as {
    translationsState: TranslationsState;
    getTranslations: () => Promise<void>;
  },
);

export interface TranslationsProviderProps {
  children: JSX.Element;
}

export enum TranslationsState {
  PENDING = 'pending',
  FAILED = 'failed',
  DONE = 'done',
  IDLE = 'idle',
}
export const TranslationsProvider = (props: TranslationsProviderProps) => {
  const { children } = props;
  const [translationsState, setTranslationsState] = useState(TranslationsState.IDLE);

  const get = useCallback(async () => {
    if (translationsState !== TranslationsState.DONE && translationsState !== TranslationsState.PENDING) {
      setTranslationsState(TranslationsState.PENDING);
      await initLanguage();
      setTranslationsState(TranslationsState.DONE);
    }
  }, [translationsState]);

  const value = useMemo(() => ({ translationsState, getTranslations: get }), [get, translationsState]);

  return <TranslationsContext.Provider value={value}>{children}</TranslationsContext.Provider>;
};
