import { useTranslation } from 'react-i18next';
import { PREFIX } from '../Skapa/constants/constants';
import Button, { ButtonType } from '../Skapa/Button/Button';
import styles from './LoginPage.module.scss';
import { useCallback } from 'react';
import { Actions } from '../../actions/enums';
import Text, { TextBodySize } from '../Skapa/Text/Text';

export const ContinueAsGuest = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation('profile');

  const goToCheckout = useCallback(() => {
    window.ikea.pubsub.publish(Actions.GO_TO_CHECKOUT);
  }, []);

  return (
    <div className={loading ? styles.checkingForMerge : undefined}>
      <Text prefix={PREFIX} headingSize={TextBodySize.M} className={styles.continueAsGuestTitle}>
        {t('loginPrompt.guestCheckout.heading')}
      </Text>

      <Text className={styles.continueAsGuestBody} prefix={PREFIX} bodySize={TextBodySize.M}>
        {t('loginPrompt.guestCheckout.body')}
      </Text>

      <Button
        prefix={PREFIX}
        onClick={goToCheckout}
        disabled={loading}
        fluid
        type={ButtonType.PRIMARY}
        text={t('loginPrompt.guestCheckout.buttonLabel')}
        data-testid={'continue-as-guest-button'}
      />

      <div className={styles.orDivider}>
        <span className={styles.line} />
        <span>{t('loginPrompt.alternativeOption.text')}</span>
        <span className={styles.line} />
      </div>
    </div>
  );
};
