import { BackendModule } from 'i18next';

import { ErrorType } from '../network/types';
import { endpointReplace } from '../network/utils/endpointReplace';

const logError = (message?: unknown, ...optionalParams: unknown[]) => {
  console.error('MVAgent', '-', message, optionalParams);
};

const backendModule: BackendModule = {
  type: 'backend',
  init: function () {},
  read: function (language, namespace, callback) {
    if (namespace === 'translations') {
      const url = `${window.location.origin}${endpointReplace(
        { ':locale': language },
        '/global/assets/commerce/fav/localisationv4/:locale/translations.json',
      )}`;

      fetch(url)
        .then((res) => res.json())
        .then((res) => callback(null, res))
        .catch(() => {
          logError('Failed to load translations', ErrorType.TRANSLATIONS_FAILED_TO_LOAD);
          callback(null, {});
        });
    } else if (namespace === 'profile') {
      const url = `${window.location.origin}${endpointReplace(
        { ':locale': language },
        '/global/assets/rossprofile/localisation/:locale/translations.json',
      )}`;

      fetch(url)
        .then((res) => res.json())
        .then((res) => callback(null, res))
        .catch(() => {
          logError('Failed to load translations', ErrorType.TRANSLATIONS_FAILED_TO_LOAD);
          callback(null, {});
        });
    } else {
      console.error('unknown call to read with namespace', namespace);
    }
  },
  create: function (languages, namespace, key) {
    logError(`Missing key ${namespace}:${key} for ${JSON.stringify(languages)}`, ErrorType.TRANSLATIONS_NOT_FOUND);
  },
};

export default backendModule;
