import { getRetailUnitAndLanguage } from '../network/utils/getRetailUnitAndLanguage';
import { getEnv } from './env';

export function getBaseUrl(href: string, newPort?: string) {
  const matches = /(https?:\/\/.+?\/[a-z]{2}\/[a-z]{2})\/?/.exec(href);
  const baseUrl = matches?.[1];

  if (!baseUrl) {
    throw Error(`The href is not of the correct format "${window.location.href}"`);
  }

  return baseUrl.includes('localhost') && newPort ? baseUrl.replace(/:\d{4}/, `:${newPort}`) : baseUrl;
}

export function getAuthServiceUrl() {
  const { language, retailUnit } = getRetailUnitAndLanguage();

  const suffix = `${retailUnit}/${language}/auth`;

  const env = getEnv();

  if (env === 'qa' || env === 'development') {
    return 'https://www.cte.ikeadt.com/' + suffix;
  }

  return 'https://www.ikea.com/' + suffix;
}

export function pathJoin(...paths: string[]) {
  let output = paths[0] ?? '';

  for (let i = 1; i < paths.length; i++) {
    const path = paths[i];
    const lastPathEndsWithSlash = output.endsWith('/');
    const nextPathStartsWithSlash = path.startsWith('/');

    if (!path) {
      continue;
    }

    if (lastPathEndsWithSlash && nextPathStartsWithSlash) {
      output += path.slice(1);
    } else if (!lastPathEndsWithSlash && !nextPathStartsWithSlash) {
      output += '/' + path;
    } else {
      output += path;
    }
  }

  return output.endsWith('/') ? output : output + '/';
}
