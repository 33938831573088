import { cartAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { TCartItem } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';
import { getErrorResponse } from '../../utils/getErrorResponse';

export const postAddItems = async (items: TCartItem[]) => {
  const path = endpointReplace({ ':siteType': SiteTypes.CART }, Endpoints.AddItemsToCart);

  const payload = items.map((item) => ({
    productId: {
      number: item.productNumber,
      type: item.productType,
    },
    quantity: parseInt(item.quantity as string, 10),
  }));

  const response = await network.post(path, payload);

  if (!response.data) {
    throw getErrorResponse(response.status, { error: { message: 'No ID' } });
  }

  const result = items.map((item, index) => ({
    ...item,
    itemId: response.data[index],
  }));

  return result;
};
