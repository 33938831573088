import { useState } from 'react';

export const useError = (initialValue: string[], allowedValues?: string[]) => {
  const [error, setError] = useState(initialValue);

  /**
   * Sets a loading state to loading.
   * @param v is a loading state.
   * @returns void
   */
  const set = (v: string) => !error.includes(v) && setError([...error, v]);

  /**
   * Sets a error state from error to not error.
   * @param v is a error state.
   * @returns void
   */
  const unset = (v: string) => setError(error.filter((l) => l !== v));

  /**
   * Checks if passed in error state is error.
   * @param v is a error state.
   * @returns boolean
   */
  const check = (v: string) => error.includes(v);

  /**
   * Gives a list of all unallowed errors.
   * @returns string[]
   */
  const unallowed = () => error.filter((e) => !allowedValues?.includes(e));

  /**
   * Clears all error state.
   * @returns void
   */
  const clear = () => setError(initialValue);

  return { value: error, set, unset, check, unallowed, clear };
};
