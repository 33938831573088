export const getEnv = (): 'development' | 'production' | 'qa' => {
  const isLocal = window.location.origin.includes('localhost');
  const isQa = window.location.origin.includes('cte.ikeadt');

  if (isLocal) {
    return 'development';
  }

  if (isQa) {
    return 'qa';
  }

  return 'production';
};
