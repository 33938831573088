import './ActionList.scss';

import SkapaActionList, {
  ActionListItem as SkapaActionListItem,
  ActionListItemProps as SkapaActionListItemProps,
  ActionListProps as SkapaActionListProps,
} from '@ingka/action-list';
import classNames from 'classnames';

import { PREFIX } from '../constants/constants';

export enum ActionListVariant {
  REGULAR = 'regular',
  COMPACT = 'compact',
}

export enum ActionListItemControl {
  DEFAULT = 'default',
  CHECKBOX = 'checkbox',
  RADIOBUTTON = 'radiobutton',
  SWITCH = 'switch',
  NAVIGATIONAL = 'navigational',
}

export enum ActionListItemVariant {
  REGULAR = 'regular',
  COMPACT = 'compact',
}

export const ActionListItem = (props: SkapaActionListItemProps) => (
  <SkapaActionListItem prefix={PREFIX} {...props} className={classNames('lessPadding', 'list-item', props.className)} />
);

const ActionList = (props: SkapaActionListProps) => (
  <SkapaActionList prefix={PREFIX} {...props} className={classNames('lessPadding', props.className)} />
);

export default ActionList;
