export enum MediaSize {
  XS = 'S1',
  S = 'S2',
  M = 'S3',
  L = 'S4',
  XL = 'S5',
}

export interface ListImageVariant {
  href: string;
  quality: string;
}

export enum MediaType {
  MAIN = '00001',
}

export const formatSrcSet = (variants: ListImageVariant[]) =>
  variants
    .reduce((previousValue, currentValue) => {
      switch (currentValue.quality) {
        case MediaSize.XL:
          return `${previousValue} ${currentValue.href} 800w,`;
        case MediaSize.L:
          return `${previousValue} ${currentValue.href} 750w,`;
        case MediaSize.M:
          return `${previousValue} ${currentValue.href} 600w,`;
        case MediaSize.S:
          return `${previousValue} ${currentValue.href} 500w,`;
        case MediaSize.XS:
          return `${previousValue} ${currentValue.href} 400w,`;
      }
      return previousValue;
    }, '')
    .slice(0, -1);
