import { getItemMetadataCompact } from '../network/endpoints/favourites/getItemMetadataCompact';
import { ItemMetadataCompact, List } from '../network/types';
import { MediaSize, MediaType, formatSrcSet } from './formatSrcSet';

export type ItemsImages = {
  itemNumber: string;
  image: {
    altText: string;
    src: string;
    srcSet?: string;
  };
};

type Media = {
  number: string;
};

type Variant = {
  quality: string;
};

const getItemImages = async (itemNos?: string) => {
  if (!itemNos) return [];
  const itemsImages: ItemsImages[] = [];
  const itemsMetadataResponse = await getItemMetadataCompact(itemNos);
  itemsMetadataResponse.results.forEach((extendedItem: ItemMetadataCompact) => {
    if (extendedItem.media) {
      const mainMedia = extendedItem.media.find((media: Media) => media.number === MediaType.MAIN);
      if (mainMedia) {
        const image = mainMedia.variants.find((variant: Variant) => variant.quality === MediaSize.M);
        if (image) {
          itemsImages.push({
            itemNumber: extendedItem.number,
            image: { src: image.href, srcSet: formatSrcSet(mainMedia.variants), altText: mainMedia.altText },
          });
        }
      }
    }
  });
  return itemsImages;
};

export const getItemImagesFromLists = async (lists: List[]) => {
  const itemNos: string[] = [];
  lists.forEach((list: List) => {
    if (list.items?.[0]) {
      itemNos.push(list.items[0].number);
    }
  });
  return getItemImages(Array.from(new Set(itemNos)).join(','));
};
