import { isAxiosError, AxiosError } from 'axios';

export interface IProblemDetails {
  detail: string;
  errors?: Record<string, string[]>;
  instance?: string;
  status: number;
  title: string;
  type: string;
}

export function isProblemDetails(unknown: unknown): unknown is IProblemDetails {
  return (
    isRecord(unknown) &&
    'detail' in unknown &&
    isString(unknown.detail) &&
    'status' in unknown &&
    isNumber(unknown.status) &&
    'title' in unknown &&
    isString(unknown.title) &&
    'type' in unknown &&
    isString(unknown.type)
  );
}

export function isAxiosProblemDetailsError(unknown: unknown): unknown is AxiosError<IProblemDetails> {
  return isAxiosError(unknown) && !!unknown.response && isProblemDetails(unknown.response.data);
}

function isRecord(unknown: unknown): unknown is Record<string | symbol | number, unknown> {
  return !!unknown && typeof unknown === 'object' && !Array.isArray(unknown);
}

function isString(unknown: unknown): unknown is string {
  return typeof unknown === 'string' || unknown instanceof String;
}

function isNumber(unknown: unknown): unknown is number {
  return typeof unknown === 'number';
}
