import './InputField.scss';

import SkapaInputField, { InputFieldProps as SkapaInputFieldProps } from '@ingka/input-field';
import { RefAttributes } from 'react';

import { PREFIX } from '../constants/constants';

export enum InputFieldType {
  NUMBER = 'number',
  PASSWORD = 'password',
  TEXT = 'text',
}

const InputField = (props: SkapaInputFieldProps & RefAttributes<HTMLInputElement>) => (
  <SkapaInputField prefix={PREFIX} {...props} />
);

export default InputField;
