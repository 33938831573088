import classNames from 'classnames';
import { Dispatch, JSX, useEffect, useState } from 'react';

import SkapaFormField, { FormFieldProps as SkapaFormFieldProps } from '../Skapa/FormField/FormField';

export const ACTIVE_ERROR_CLASS = 'form-input-field-with-error';

export enum RuleType {
  REQUIRED = 'required',
  MAX_LENGTH = 'max_length',
  MIN_LENGTH = 'min_length',
  REGEX = 'regex',
}

type Rule =
  | {
      type: RuleType.MAX_LENGTH | RuleType.MIN_LENGTH;
      value: number;
      message: string;
    }
  | {
      type: RuleType.REQUIRED;
      value: boolean;
      message: string;
    }
  | {
      type: RuleType.REGEX;
      value: RegExp;
      message: string;
    };

type Input = string | number;
interface FormFieldProps extends Pick<SkapaFormFieldProps, 'children' | 'className' | 'onBlur'> {
  children: JSX.Element;
  validationRules?: Rule[];
  hideCharacterLimit?: boolean;
  forceValidate?: boolean;
}

const validateRule = (input: string, rule: Rule) => {
  switch (rule.type) {
    case RuleType.MAX_LENGTH:
      return input.length > rule.value;
    case RuleType.MIN_LENGTH:
      return input.length < rule.value;
    case RuleType.REQUIRED:
      return !input.trim().length;
    case RuleType.REGEX:
      return !rule.value.exec(input);
  }

  return false;
};

const validate = (input: Input, rules: Rule[] = []): Rule | undefined => {
  if (typeof input !== 'string') return;

  for (const rule of rules) {
    if (validateRule(input, rule)) {
      return rule;
    }
  }
};

export const FormField = (props: FormFieldProps) => {
  const [isValid, setIsValid] = useState(true);
  return <FormFieldExternalValid {...props} isValid={isValid} setIsValid={setIsValid} />;
};

export const FormFieldExternalValid = (props: FormFieldProps & { isValid: boolean; setIsValid: Dispatch<boolean> }) => {
  const { validationRules, forceValidate, children, hideCharacterLimit, isValid, setIsValid, ...formFieldProps } =
    props;

  const [shouldValidate, setShouldValidate] = useState(forceValidate);
  const [errorMessage, setErrorMessage] = useState('');

  const value = children.props.value;
  const maxLength = validationRules?.find((validationRule) => validationRule.type === RuleType.MAX_LENGTH)
    ?.value as number;

  useEffect(() => {
    if (forceValidate) {
      setShouldValidate(true);
    }
  }, [forceValidate]);

  useEffect(() => {
    const validationError = validate(value, validationRules);
    if (validationError) setErrorMessage(validationError.message);
    setIsValid(!validationError);
  }, [setIsValid, shouldValidate, validationRules, value]);

  return (
    <SkapaFormField
      {...formFieldProps}
      characterLimit={hideCharacterLimit ? undefined : maxLength}
      onBlur={(event) => {
        if (!shouldValidate && !isValid) {
          setShouldValidate(true);
        }
        formFieldProps.onBlur?.(event);
      }}
      valid={isValid}
      shouldValidate={shouldValidate}
      validation={{
        msg: errorMessage,
      }}
      className={classNames(
        {
          [formFieldProps.className as string]: !!formFieldProps.className,
        },
        {
          [ACTIVE_ERROR_CLASS]: !isValid,
        },
      )}
    >
      {children}
    </SkapaFormField>
  );
};
