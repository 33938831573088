import { getBaseUrl, pathJoin } from './url';

function profilePagesUrl(subpath: string, addOpenedByParam: boolean) {
  const href = window.location.href;
  const path = pathJoin(getBaseUrl(href, '3006'), 'profile', subpath);
  const url = new URL(path);

  if (addOpenedByParam) {
    url.searchParams.set('openedBy', window.location.href);
  }

  return url.toString();
}

export const ProfilePagesUrls = {
  migrate: () => profilePagesUrl('migrate-password', false),
  resetPassword: () => profilePagesUrl('reset-password', false),
  missingInfo: () => profilePagesUrl('missing-info', true),
  signUp: () => profilePagesUrl('sign-up', true),
  menu: () => profilePagesUrl('menu', true),
};
