import './Toast.scss';

import SkapaToast, { ToastProps as SkapaToastProps, ToastText as SkapaToastText } from '@ingka/toast';

import { PREFIX } from '../constants/constants';

export type { SkapaToastText as ToastText };
export type ActionClick = (...args: any[]) => any;
export type OnCloseRequest = (...args: any[]) => any;
export type { SkapaToastProps as ToastProps };

const Toast = (props: SkapaToastProps) => <SkapaToast prefix={PREFIX} {...props} />;

export default Toast;
