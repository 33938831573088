import './Button.scss';

import SkapaButton, { ButtonProps as SkapaButtonProps } from '@ingka/button';
import { RefAttributes } from 'react';

import { PREFIX } from '../constants/constants';

export enum ButtonType {
  PRIMARY = 'primary',
  EMPHASISED = 'emphasised',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger',
  PLAIN = 'plain',
  IMAGE_OVERLAY = 'image-overlay',
}

const Button = (props: SkapaButtonProps & RefAttributes<HTMLAnchorElement | HTMLButtonElement>) => (
  <SkapaButton prefix={PREFIX} {...props} />
);

export default Button;
