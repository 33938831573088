import './Choice.scss';

import SkapaChoice, {
  ChoiceItem as SkapaChoiceItem,
  ChoiceItemProps as SkapaChoiceItemProps,
  ChoiceProps as SkapaChoiceProps,
} from '@ingka/choice';
import classNames from 'classnames';

import { PREFIX } from '../constants/constants';

export type { SkapaChoiceItemProps as ChoiceItemProps };

export const ChoiceItem = ({ ...props }: SkapaChoiceItemProps) => <SkapaChoiceItem prefix={PREFIX} {...props} />;

const Choice = (props: SkapaChoiceProps) => (
  <SkapaChoice prefix={PREFIX} {...props} className={classNames('lessPadding', props.className)} />
);

export default Choice;
