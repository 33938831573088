import './List.scss';

import SkapaList, { ListProps as SkapaListProps } from '@ingka/list';

import { PREFIX } from '../constants/constants';

export enum ListVariation {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

const List = (props: SkapaListProps) => <SkapaList prefix={PREFIX} {...props} />;

export default List;
