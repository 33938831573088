import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { GetItemMetadataCompactResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const getItemMetadataCompact = async (itemNos: string) => {
  const path = endpointReplace(
    { ':siteType': SiteTypes.FAVOURITES, ':itemNos': itemNos },
    Endpoints.GetItemMetadataCompact,
  );

  const response = await network.get<GetItemMetadataCompactResponse>(path);

  return response.data;
};
