import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { PostItemBody, PostItemResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const postItem = async (listId: string, item: PostItemBody) => {
  const path = endpointReplace({ ':siteType': SiteTypes.FAVOURITES, ':listId': listId }, Endpoints.AddItemToFavourites);

  const response = await network.post<PostItemResponse>(path, {
    itemNumber: item.itemNumber,
    itemType: item.itemType,
    quantity: item.quantity,
  });

  return response.data;
};
