import { ACTIVE_ERROR_CLASS } from '../components/FormField/FormField';

export const formValidation = (form: HTMLFormElement) => {
  const inputs = Array.from(form.getElementsByTagName('input'));
  const focusedInput = document.activeElement;

  let valid = true;

  // Triggers validation on fields
  inputs.forEach((input) => {
    input?.focus();
    input?.blur();
    valid = !form?.querySelector(`.${ACTIVE_ERROR_CLASS}`);
  });

  if (focusedInput?.nodeName === 'INPUT') (focusedInput as HTMLInputElement).focus();

  return valid;
};
