import { getRetailUnitAndLanguage } from '../network/utils/getRetailUnitAndLanguage';
import { retailUnitsWithoutCookieConsent } from './retailUnitsWithoutCookieConsent';
import { stringToBoolean } from '@mvecom/common/utils/stringToBoolean';

const { retailUnit } = getRetailUnitAndLanguage();

window.ikea = window.ikea || {};
window.ikea.mvAgent = window.ikea.mvAgent || {};

const noConsentNeeded =
  stringToBoolean(process.env.REACT_APP_FORCE_COOKIE_CONSENT) || retailUnitsWithoutCookieConsent.includes(retailUnit);

window.ikea.mvAgent.cookieConsent = window.ikea.cookieConsent || {
  hasConsent: () => noConsentNeeded,
  interactedWithBanner: noConsentNeeded,
  status: {
    1: noConsentNeeded,
    2: noConsentNeeded,
    3: noConsentNeeded,
    4: noConsentNeeded,
    showBanner: noConsentNeeded,
  },
  statusStringified: JSON.stringify({
    1: noConsentNeeded,
    2: noConsentNeeded,
    3: noConsentNeeded,
    4: noConsentNeeded,
    showBanner: noConsentNeeded,
  }),
  vendor: 'mock',
};

const mvAgentCookieConsent = new Event('mvAgentCookieConsent');
document.dispatchEvent(mvAgentCookieConsent);

document.addEventListener('ikeaCookieConsent', () => {
  document.dispatchEvent(mvAgentCookieConsent);
});
