import './RemoveFromFavouritesModal.scss';

import trashCanIcon from '@ingka/ssr-icon/paths/trash-can';
import { useState } from 'react';

import { useLoading } from '../../../hooks/useLoading';
import ActionList, { ActionListItem, ActionListItemControl } from '../../Skapa/ActionList/ActionList';
import Button from '../../Skapa/Button/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, SheetSize, Sheets } from '../../Skapa/Modal/Modal';
import Text, { TextHeadingSize, TextTagName } from '../../Skapa/Text/Text';

type Labels = {
  modal: {
    title: string;
    body: string;
  };
  footer: {
    remove: {
      label: string;
    };
  };
};
type List = {
  id: string;
  title: string;
  image: {
    alt?: string;
    src?: string;
    srcSet?: string;
    fallback?: boolean;
  };
};

enum LocalLoading {
  REMOVE = 'remove',
}
export interface RemoveFromFavouritesModalProps {
  isOpen: boolean;
  labels: Labels;
  lists: List[];
  onClose: () => void;
  onBack: () => void;
  onRemove: (lists: string[]) => Promise<void>;
  onRemoveCallback: (lists: string[], listName?: string) => void;
  onRemoveFallback: (error: any) => void;
}
export const RemoveFromFavouritesModal = (props: RemoveFromFavouritesModalProps) => {
  const { isOpen, labels, lists, onClose, onBack, onRemove, onRemoveCallback, onRemoveFallback } = props;
  const localLoading = useLoading<LocalLoading>([]);
  const [checkedLists, setCheckedLists] = useState<string[]>([]);

  return (
    <Modal
      id={'remove-from-favourites-modal'}
      visible={isOpen}
      handleCloseBtn={() => {
        setCheckedLists([]);
        onClose();
      }}
    >
      <Sheets
        id={'remove-from-favourites-sheets-modal'}
        header={
          <ModalHeader
            title={labels.modal.title}
            backBtnClick={() => {
              setCheckedLists([]);
              onBack();
            }}
          />
        }
        size={SheetSize.SMALL}
        footer={
          <ModalFooter>
            <Button
              ssrIcon={trashCanIcon}
              fluid={true}
              onClick={async () => {
                try {
                  localLoading.set(LocalLoading.REMOVE);
                  await onRemove(checkedLists);
                  onRemoveCallback(
                    checkedLists,
                    checkedLists.length === 1 ? lists.find((list) => list.id === checkedLists[0])?.title : undefined,
                  );
                } catch (error: any) {
                  onRemoveFallback(error);
                } finally {
                  setCheckedLists([]);
                  localLoading.unset(LocalLoading.REMOVE);
                }
              }}
              text={labels.footer.remove.label}
              loading={localLoading.check(LocalLoading.REMOVE)}
              disabled={checkedLists.length === 0}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <Text tagName={TextTagName.H1} headingSize={TextHeadingSize.M} className={'remove-body'}>
            {labels.modal.body}
          </Text>
          <ActionList id={'remove-item-action-list'}>
            {lists.map((list) => (
              <ActionListItem
                key={list.id}
                label={list.title}
                control={ActionListItemControl.CHECKBOX}
                onChange={(event) => {
                  if (event.target.checked) {
                    setCheckedLists([...checkedLists, list.id]);
                  } else {
                    setCheckedLists(checkedLists.filter((listId) => listId !== list.id));
                  }
                }}
                disabled={localLoading.check(LocalLoading.REMOVE)}
                image={list.image.src}
                imageAltText={list.image.alt}
                imageProps={{ srcSet: list.image.srcSet, fallback: list.image.fallback }}
              />
            ))}
          </ActionList>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
