import { useLoading } from '../../../hooks/useLoading';
import Button, { ButtonType } from '../../Skapa/Button/Button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '../../Skapa/Modal/Modal';
import Text, { TextTagName } from '../../Skapa/Text/Text';

type Labels = {
  modal: {
    title: string;
    body: string;
  };
  buttons: {
    secondary?: {
      label: string;
    };
    primary: {
      label: string;
    };
  };
};
enum LocalLoading {
  CONFIRM = 'confirm',
}
export interface ConfirmationModalProps {
  isOpen: boolean;
  labels: Labels;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}
export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { isOpen, labels, onClose, onConfirm } = props;
  const localLoading = useLoading<LocalLoading>([]);

  return (
    <Modal id={'confirmation-modal'} escapable={false} visible={isOpen} handleCloseBtn={() => onClose()}>
      <Prompt
        id={'confirmation-prompt-modal'}
        titleId={'confirmation-prompt-modal'}
        title={labels.modal.title}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              text={labels.buttons.primary.label}
              small={false}
              type={ButtonType.PRIMARY}
              loading={localLoading.check(LocalLoading.CONFIRM)}
              onClick={async () => {
                localLoading.set(LocalLoading.CONFIRM);
                await onConfirm();
                localLoading.unset(LocalLoading.CONFIRM);
              }}
            />
            {labels.buttons.secondary && (
              <Button
                text={labels.buttons.secondary.label}
                small={false}
                type={ButtonType.SECONDARY}
                disabled={localLoading.check(LocalLoading.CONFIRM)}
                onClick={onClose}
              />
            )}
          </ModalFooter>
        }
      >
        <Text tagName={TextTagName.P}>{labels.modal.body}</Text>
      </Prompt>
    </Modal>
  );
};
