import { AxiosError } from 'axios';

import { axiosRetryDefault, favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { GetSessionResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const getSession = async () => {
  const path = endpointReplace({ ':siteType': SiteTypes.FAVOURITES }, Endpoints.GetSession);

  const response = await network.get<GetSessionResponse>(path, {
    'axios-retry': {
      retryCondition: (error: AxiosError<any>) => {
        if (error.response?.status === 400) return true;
        return axiosRetryDefault(error);
      },
    },
  });

  return response.data;
};
