import { cartAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { CartUserReplacementResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const postUserReplacement = async (userId: string, userSource: string) => {
  const path = endpointReplace({ ':siteType': SiteTypes.CART }, Endpoints.PostCartUserReplacement);

  const response = await network.post<CartUserReplacementResponse>(path, {
    userId: userId,
    userSource: userSource,
  });

  return response.data;
};
