import { favouritesAxiosInstance as network } from '../../axios';
import { Endpoints, SiteTypes } from '../../enums';
import { CreateListResponse } from '../../types';
import { endpointReplace } from '../../utils/endpointReplace';

export const createList = async (name?: string) => {
  const path = endpointReplace({ ':siteType': SiteTypes.FAVOURITES }, Endpoints.Lists);

  const response = await network.post<CreateListResponse>(path, {
    name: name,
  });

  return response.data;
};
