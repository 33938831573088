import { useState } from 'react';

export const useLoading = <T>(initialValue: T[]) => {
  const [loading, setLoading] = useState(initialValue);

  /**
   * Sets a loading state to loading.
   * @param v is a loading state.
   * @returns void
   */
  const set = (v: T) => setLoading([...loading, v]);

  /**
   * Sets a loading state from loading to not loading.
   * @param v is a loading state.
   * @returns void
   */
  const unset = (v: T) => setLoading(loading.filter((l) => l !== v));

  /**
   * Checks if passed in loading state is loading.
   * @param v is a loading state.
   * @returns boolean
   */
  const check = (v: T) => loading.includes(v);

  /**
   * Checks if passed in loading state is loading.
   * @param v is an array of loading states.
   * @returns boolean
   */
  const checkOneOf = (v: T[]) => !!v.find((value) => loading.includes(value));

  /**
   * Clears all loading state.
   * @returns void
   */
  const clear = () => setLoading(initialValue);

  return { value: loading, set, unset, check, checkOneOf, clear };
};
