export enum Actions {
  ADD_TO_CART_ACTION = 'CART_CLIENT/ADD_TO_CART',
  ADD_TO_CART_INITIATE = 'CART_CLIENT/ADD_TO_CART_INITIATE',
  ADD_TO_CART_SUCCESS = 'CART_CLIENT/ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAIL = 'CART_CLIENT/ADD_TO_CART_FAIL',
  CART_AGENT_LOADED = 'CART_CLIENT/AGENT_LOADED',
  SHOW_REC_MODAL = 'CART_CLIENT/SHOW_REC_MODAL',
  ADD_TO_FAVOURITES_ACTION = 'FAVOURITE_CLIENT/ADD_TO_FAVOURITES',
  ADD_TO_FAVOURITES_INITIATE = 'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_INITIATE',
  ADD_TO_FAVOURITES_SUCCESS = 'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_SUCCESS',
  ADD_TO_FAVOURITES_FAIL = 'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_FAIL',
  GO_TO_CHECKOUT = 'CART_CLIENT/GO_TO_CHECKOUT',
  REMOVE_FROM_FAVOURITES_ACTION = 'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES',
  REMOVE_FROM_FAVOURITES_INITIATE = 'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_INITIATE',
  REMOVE_FROM_FAVOURITES_SUCCESS = 'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_SUCCESS',
  REMOVE_FROM_FAVOURITES_FAIL = 'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_FAIL',
  FAVOURITES_AGENT_LOADED = 'FAVOURITE_CLIENT/AGENT_LOADED',
  FAVOURITES_UPDATED = 'FAVOURITE_CLIENT/FAVOURITES_UPDATED',
  USER_LOGGED_IN = 'user/loggedIn',
  USER_LOGGED_IN_AGENT_POST_FAVOURITES_WORK_SUCCESS = 'user/loggedInAgentPostFavouritesWorkSuccess',
  USER_LOGGED_IN_AGENT_POST_FAVOURITES_WORK_FAIL = 'user/loggedInAgentPostFavouritesWorkFail',
  USER_LOGGED_IN_AGENT_POST_CART_WORK_SUCCESS = 'user/loggedInAgentPostCartWorkSuccess',
  USER_LOGGED_IN_AGENT_POST_CART_WORK_FAIL = 'user/loggedInAgentPostWorkFail',
  USER_INFO_AVAILABLE = 'user/infoAvailable',
  USER_LOGGED_OUT = 'user/loggedOut',
  USER_LOGGED_OUT_AGENT_POST_FAVOURITES_WORK_SUCCESS = 'user/loggedOutAgentPostFavouritesWorkSuccess',
  USER_LOGGED_OUT_AGENT_POST_FAVOURITES_WORK_FAIL = 'user/loggedOutAgentPostFavouritesWorkFail',
  USER_LOGGED_OUT_AGENT_POST_CART_WORK_SUCCESS = 'user/loggedOutAgentPostCartWorkSuccess',
  USER_LOGGED_OUT_AGENT_POST_CART_WORK_FAIL = 'user/loggedOutAgentCartPostWorkFail',
  PROFILE_OPEN_MENU = 'PROFILE/OPEN_MENU',
  PROFILE_SIGN_UP = 'PROFILE/SIGN_UP',
  PROFILE_OPEN_LOGIN_REMINDER = 'PROFILE/OPEN_LOGIN_REMINDER',
}

export enum StorageState {
  SHOW_FAVOURITES_MERGE_EXCEEDED_MAX_AMOUNT_PROMPT = 'show_favourites_merge_exceeded_max_amount_prompt',
}

export enum LocalStorageKeys {
  SIGNED_UP_WITH_REDIRECT = 'SIGNED_UP_WITH_REDIRECT',
  LOGGED_IN_WITH_REDIRECT = 'LOGGED_IN_WITH_REDIRECT',
}
