import './InlineMessage.scss';

import SkapaInlineMessage, { InlineMessageProps as SkapaInlineMessageProps } from '@ingka/inline-message';

import { PREFIX } from '../constants/constants';

export enum InlineMessageVariant {
  INFORMATIVE = 'informative',
  POSITIVE = 'positive',
  CAUTIONARY = 'cautionary',
  NEGATIVE = 'negative',
}

const InlineMessage = (props: SkapaInlineMessageProps) => <SkapaInlineMessage prefix={PREFIX} {...props} />;

export default InlineMessage;
