import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import { AxiosError } from 'axios';
import { ChangeEventHandler, FormEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { FormFieldExternalValid, RuleType } from '../FormField/FormField';
import InputField from '../Skapa/InputField/InputField';
import { useTranslation } from 'react-i18next';
import { isAxiosProblemDetailsError } from './types';
import { StatusCode } from '@mvecom/common/enums/StatusCode';

import styles from './LoginPage.module.scss';
import { ProfilePagesUrls } from '../../utils/Urls';
import { ContinueAsGuest } from './ContinueAsGuest';
import { ButtonType } from '../Skapa/Button/Button';

interface ILoginPageContentRight {
  closeMenu: () => void;
  skapaPrefix: string;
  isReminder: boolean;
  login: (email: string, password: string) => void;
  loading: boolean;
  loginError: AxiosError | undefined;
}

const EMAIL_VALIDATION = {
  maxLength: 80,
  regex: /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
};

const PASSWORD_VALIDATION = {
  minLength: 1,
};

export const LoginPage = ({
  closeMenu,
  skapaPrefix,
  isReminder,
  login,
  loading,
  loginError,
}: ILoginPageContentRight): ReactElement => {
  const { t } = useTranslation('profile');

  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forceValidateEmail, setForceValidateEmail] = useState(false);
  const [forceValidatePassword, setForceValidatePassword] = useState(false);

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setEmail(e.target.value);
    setForceValidateEmail(false);
  }, []);

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setPassword(e.target.value);
      setForceValidatePassword(false);
    },
    [setPassword],
  );

  const problemDetails = useMemo(
    () => (isAxiosProblemDetailsError(loginError) ? loginError.response?.data : undefined),
    [loginError],
  );

  useEffect(() => {
    if (problemDetails?.type === 'accountExpired' || problemDetails?.type === 'accountMigrated') {
      window.location.href = ProfilePagesUrls.migrate();
    }
  }, [closeMenu, email, password, problemDetails?.type]);

  const handleLogin = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setForceValidateEmail(true);
      setForceValidatePassword(true);

      if (emailValid && passwordValid) {
        login(email, password);
      }
    },
    [email, emailValid, login, password, passwordValid],
  );

  const goToSignUpPage = useCallback(() => {
    window.location.href = ProfilePagesUrls.signUp();
  }, []);

  return (
    <div>
      {isReminder && <ContinueAsGuest loading={loading} />}

      <h2 className={styles.loginPageHeader}>{t('loginPage.loginPageHeader')}</h2>
      {!isReminder && <p className={styles.loginPageDescription}>{t('loginPage.loginPageHeaderDescription')}</p>}

      {loginError && (
        <InlineMessage
          prefix={skapaPrefix}
          title={problemDetails?.status === StatusCode.UNAUTHORIZED ? undefined : t('loginPage.errorMessageTitle')}
          body={
            problemDetails?.status === StatusCode.UNAUTHORIZED
              ? t('loginPage.errorIncorrectInfoMessage')
              : t('loginPage.errorMessage')
          }
          data-testid="error-message"
          variant="negative"
          className={styles.inlineMessage}
        />
      )}

      <form onSubmit={handleLogin}>
        <fieldset>
          <FormFieldExternalValid
            validationRules={[
              {
                type: RuleType.MAX_LENGTH,
                value: EMAIL_VALIDATION.maxLength,
                message: t('formInputField.invalidEmail'),
              },
              {
                type: RuleType.REGEX,
                value: EMAIL_VALIDATION.regex,
                message: t('formInputField.invalidEmail'),
              },
            ]}
            hideCharacterLimit
            forceValidate={forceValidateEmail}
            isValid={emailValid}
            setIsValid={setEmailValid}
            className={styles.loginEmail}
          >
            <InputField
              disabled={loading}
              id="login-email"
              autoComplete="email"
              type="text"
              name="email"
              value={email}
              label={t('formInputField.credentialEmail')}
              onChange={onEmailChange}
            />
          </FormFieldExternalValid>
          <FormFieldExternalValid
            validationRules={[
              {
                type: RuleType.MIN_LENGTH,
                value: PASSWORD_VALIDATION.minLength,
                message: t('formInputField.isRequired', { name: t('formInputField.password.name').toLowerCase() }),
              },
            ]}
            hideCharacterLimit
            forceValidate={forceValidatePassword}
            isValid={passwordValid}
            setIsValid={setPasswordValid}
            className={styles.loginPassword}
          >
            <InputField
              disabled={loading}
              id="login-password"
              autoComplete="current-password"
              type="password"
              name="password"
              label={t('formInputField.credentialPassword')}
              onChange={onPasswordChange}
            />
          </FormFieldExternalValid>
        </fieldset>

        <a className={styles.resetPasswordLink} data-testid="reset-password" href={ProfilePagesUrls.resetPassword()}>
          {t('loginPage.forgotPassword')}
        </a>

        <Button
          className={styles.loginButton}
          prefix={skapaPrefix}
          fluid
          htmlType="submit"
          loading={loading}
          data-testid="login"
          text={t('loginPage.loginButton')}
          type={isReminder ? ButtonType.SECONDARY : ButtonType.PRIMARY}
        />

        {!isReminder && (
          <>
            <div className={styles.divider}>
              <span>{t('loginPrompt.newIkea.text')}</span>
            </div>

            <Button
              prefix={skapaPrefix}
              disabled={loading}
              fluid
              onClick={goToSignUpPage}
              data-testid="create-account"
              text={t('loginPage.createAccountButton')}
              type="secondary"
            />
          </>
        )}
      </form>
    </div>
  );
};
